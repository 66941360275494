import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import {
  faAngleDoubleUp,
  faAngleDoubleDown,
} from '@fortawesome/free-solid-svg-icons'
import { FileResponse } from '@rsmus/ecp-financeservice'
import { format } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { visuallyHidden } from '@mui/utils'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import tokens from '../../../styles/tokens.json'
import { Styles } from '../../../types'
import OverFlowToolTip from '../../OverFlowToolTip'
import {
  getPayableAmount,
  getInvoicePayments,
  ConfirmPaymentResponse,
} from '../../../store/invoices/invoiceSelectedInvoicesSlice'
import { formatCurrency } from '../../../rsmCoreComponents/utils/formatters'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'
import InvoicesHeader from '../Invoices/InvoicesHeader'
import api from '../../../api'
import downloadFileStream from '../../../rsmCoreComponents/utils/fileStreamUtils'
import { isCemFeatureEnabled } from '../../../rsmCoreComponents/utils/featureFlagUtils'
import {
  CEM_FEATURE_PAYMENT_PAY_INVOICES,
  CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
} from '../../../utils/constants/constants'
import { getCemFeatures } from '../../../store/userInfo/userInfoSlice'
import {
  getPaidInvoices,
  getPaymentMethodDisplay,
  handleNavigateBackToInvoices,
} from './PaymentSuccess.service'

interface PayNowPaymentSuccessProps {
  paymentTransaction: ConfirmPaymentResponse
}

const styles: Styles = {
  invoicesPaymentTable: (theme) => ({
    // -------------------------------------------------------------------------
    // TODO: (Ideally move these styles to a RsmTable variant, if possible.)
    // -------------------------------------------------------------------------
    '&.MuiTable-root': {
      tableLayout: 'fixed',
      width: '100%',
      '& .MuiTableCell-root': {
        borderBottom: 'none',
      },
      '& th.MuiTableCell-root': {
        fontFamily: 'Prelo-Bold, sans-serif',
      },
      '& td.MuiTableCell-root': {
        fontFamily: 'Prelo-Book, sans-serif',
      },
      '& .MuiTableCell-head': {
        padding: '1rem',
        fontSize: '0.875rem',
        lineHeight: '1rem',
        textTransform: 'uppercase',
        verticalAlign: 'top',
      },
      '& tbody': {
        '& .MuiTableRow-root': {
          '&:nth-of-type(odd)': {
            backgroundColor: tokens.colors.rsmGray.accessibility,
          },
        },
      },
      '& .MuiTableCell-body': {
        padding: '1.5rem 1rem',
        fontSize: '1rem',
        lineHeight: '1.5rem',
      },
      '.MuiFormControlLabel-root': {
        margin: '0rem',
      },
      '.MuiCheckbox-root': {
        boxSizing: 'content-box',
        padding: '0.5rem',
      },
    },
    '& .MuiTableCell-root': {
      '&:nth-of-type(10)': {
        width: '3.5rem',
        padding: '0.5rem',
      },
    },
    '& .MuiTableCell-body': {
      '&:nth-of-type(2), &:nth-of-type(3), :nth-of-type(5), :nth-of-type(6), :nth-of-type(9)':
        {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      ':nth-of-type(6)': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    [theme.breakpoints.only('tablet')]: {
      '& .MuiTableCell-root': {
        '&:nth-of-type(2), &:nth-of-type(4), &:nth-of-type(5), &:nth-of-type(6), &:nth-of-type(8), &:nth-of-type(9)':
          {
            display: 'none',
          },
      },
    },
    [theme.breakpoints.only('mobile')]: {
      '& .MuiTableCell-root': {
        '&:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(4), &:nth-of-type(5), &:nth-of-type(6), &:nth-of-type(8), &:nth-of-type(9)':
          {
            display: 'none',
          },
        '&:nth-of-type(3)': {
          width: '50%',
        },
        '&:nth-of-type(7)': {
          width: '50%',
        },
      },
    },
  }),
  date: {
    textTransform: 'uppercase',
  },
  textButton: (theme) => ({
    color: theme.palette.secondary.main,
    marginBottom: '3.125rem',
  }),
  paymenthistoryLink: (theme) => ({
    [theme.breakpoints.up('tablet')]: {
      color: theme.palette.secondary.main,
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '1.5rem',
      justifyContent: 'start',
      verticalAlign: 'top',
      padding: 0,
      marginBottom: '1rem',
    },
    [theme.breakpoints.only('mobile')]: {
      color: theme.palette.secondary.main,
      fontWeight: 400,
      fontSize: '0.75rem',
      padding: 0,
    },
  }),
  paymenthistorylinkButton: {
    display: 'block',
    textDecoration: 'underline',
  },
  toggle: (theme) => ({
    display: 'block',
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    marginRight: '0.5rem',
  }),
  detailContainer: (theme) => ({
    [theme.breakpoints.only('desktop')]: {
      paddingTop: '4rem',
      marginLeft: '6.5rem',
      marginRight: '6.5rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingTop: '4rem',
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
    [theme.breakpoints.only('mobile')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  }),
  detailSection: () => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'start',
  }),
  details: () => ({
    flex: '1 1 auto',
  }),
  detailRow: () => ({
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'start',
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
  }),
  detailLabel: (theme) => ({
    fontWeight: 'bold',
    [theme.breakpoints.only('desktop')]: {
      minWidth: '15rem',
    },
    [theme.breakpoints.only('tablet')]: {
      minWidth: '10rem',
      fontSize: '0.875rem',
    },
    [theme.breakpoints.only('mobile')]: {
      minWidth: '7rem',
      fontSize: '0.875rem',
    },
  }),
  detailLabelContent: (theme) => ({
    [theme.breakpoints.only('tablet')]: {
      minWidth: '10rem',
      fontSize: '0.875rem',
    },
    [theme.breakpoints.only('mobile')]: {
      minWidth: '3rem',
      fontSize: '0.875rem',
      paddingLeft: '0.5rem',
    },
  }),
  autopay: () => ({
    flex: '0 0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20rem',
    height: '10rem',
  }),
  amountSubText: () => ({
    fontSize: '0.75rem',
  }),
  ButtonDownload: (theme) => ({
    marginRight: '0.5rem',
    [theme.breakpoints.only('tablet')]: {
      width: '25rem',
      top: '15rem',
      left: '1rem',
    },
    [theme.breakpoints.only('mobile')]: {
      width: '14.5rem',
    },
    [theme.breakpoints.only('desktop')]: {
      display: 'block',
      marginBottom: '0.5rem',
      width: '16rem',
    },
  }),
  InvoicesDetails: (theme) => ({
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    [theme.breakpoints.only('mobile')]: {
      gridTemplateColumns: 'auto',
      textAlign: 'start',
    },
  }),
}

const PayNowPaymentSuccess = ({
  paymentTransaction,
}: PayNowPaymentSuccessProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const cemFeatures = useSelector(getCemFeatures)
  const invoicePayments = useSelector(getInvoicePayments)
  const payableAmount = useSelector(getPayableAmount)
  const navigate = useNavigate()
  const [invoiceViewState, setInvoiceViewState] = useState(false)
  const { isDesktop } = useDeviceType()
  const { isMobile } = useDeviceType()

  const handleBackToInvoices = () => {
    handleNavigateBackToInvoices(dispatch, navigate)
  }

  useEffect(() => {
    // Prevent user from accessing this page directly.
    if (invoicePayments.length === 0) {
      navigate('/invoicing')
    }
  }, [])

  const handleTogglePaidInvoices = () => {
    setInvoiceViewState(!invoiceViewState)
  }

  const handleDownloadReceipt = useCallback(async () => {
    const response: FileResponse =
      await api.finance.invoiceDocument_DownloadPaymentReceipt(
        paymentTransaction?.transactionId || '',
      )
    downloadFileStream(response)
  }, [
    api.finance.invoiceDocument_DownloadPaymentReceipt,
    downloadFileStream,
    paymentTransaction,
  ])

  const getButtonSlot = (): JSX.Element | undefined =>
    isCemFeatureEnabled(
      [
        CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
        CEM_FEATURE_PAYMENT_PAY_INVOICES,
      ],
      cemFeatures,
      'any',
    ) ? (
      <Button
        sx={styles.ButtonDownload}
        size="small"
        aria-label={t('Invoicing.PaymentSuccessPage.DownloadReceipt')}
        variant="outlined"
        onClick={handleDownloadReceipt}>
        {t('Invoicing.PaymentSuccessPage.DownloadReceipt')}
      </Button>
    ) : undefined

  const paynowSucessDescription = (
    <Box>
      {t('Invoicing.PaymentSuccessPage.PaynowPaymentSucessDescription')}{' '}
      <Button
        onClick={() => navigate('/invoicing/payments')}
        sx={styles.paymenthistoryLink}
        role="link">
        <Box component="span" sx={styles.paymenthistorylinkButton}>
          {t('Invoicing.PaymentSuccessPage.PaymentSucessDescription2')}{' '}
        </Box>
      </Button>{' '}
      {t('Invoicing.PaymentSuccessPage.PaymentSucessDescription3')}
    </Box>
  )

  return (
    <>
      <InvoicesHeader
        primaryHeaderId="PaySelected"
        primaryHeaderText={t('Invoicing.PaymentSuccessPage.PaymentSuccess')}
        primaryHeaderDescription={paynowSucessDescription}
        buttonSlot={getButtonSlot()}
        backButtonId="BackToInvoices"
        backButtonText={t('Invoicing.BackToInvoices')}
        handleNavigation={handleBackToInvoices}
        amountHeaderId="PaidAmount"
        amountHeaderText={t('Invoicing.PaidAmount')}
        amount={payableAmount}
      />
      <Box sx={{ backgroundColor: tokens.colors.white }}>
        <Box sx={styles.detailContainer}>
          <Box sx={styles.detailSection}>
            <Box sx={styles.details}>
              <Box sx={styles.detailRow}>
                <Box sx={styles.detailLabel}>
                  {t('Invoicing.PaymentSuccessPage.TransactionId')}
                </Box>
                <Box sx={styles.detailLabelContent}>
                  {paymentTransaction?.transactionId}
                </Box>
              </Box>
              <hr aria-hidden="true" />
              <Box sx={styles.detailRow}>
                <Box sx={styles.detailLabel}>
                  {t('Invoicing.PaymentSuccessPage.TransactionDate')}
                </Box>
                <Box sx={styles.detailLabelContent}>
                  {t('Invoicing.PaymentSuccessPage.TransactionDateFormat', {
                    date: paymentTransaction?.transactionDate,
                  })}
                </Box>
              </Box>
              <hr aria-hidden="true" />
              <Box sx={styles.detailRow}>
                <Box sx={styles.detailLabel}>
                  {t('Invoicing.PaymentSuccessPage.PaymentMethod')}
                </Box>
                <Box sx={styles.detailLabelContent}>
                  {getPaymentMethodDisplay(
                    paymentTransaction?.paymentMethod,
                    paymentTransaction?.accountNumber,
                  )}
                </Box>
              </Box>
              <hr aria-hidden="true" />
              <Box sx={styles.detailRow}>
                <Box sx={styles.detailLabel}>
                  {t('Invoicing.PaymentSuccessPage.PayerName')}
                </Box>
                <Box sx={styles.detailLabelContent}>
                  {paymentTransaction?.payer}
                </Box>
              </Box>
              {!isMobile && (
                <>
                  <hr aria-hidden="true" />
                  <Box sx={styles.detailRow}>
                    <Box sx={styles.detailLabel}>
                      {t('Invoicing.PaymentSuccessPage.InvoicesPaid')}
                    </Box>
                    <Box sx={styles.detailLabelContent}>
                      {getPaidInvoices(invoicePayments).map((invoice) => (
                        <Box sx={styles.InvoicesDetails}>
                          <Box>{invoice.customerName}</Box>
                          <Box>{invoice.invoices}</Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </>
              )}
            </Box>
            {isDesktop && <Box sx={styles.autopay} />}
          </Box>
          {!isMobile && (
            <Button
              data-testid="Btn_Invoicing_ViewPaidInvoices"
              onClick={handleTogglePaidInvoices}
              aria-atomic
              aria-expanded={invoiceViewState}
              aria-controls="invoicesPaymentTable"
              aria-live="polite"
              sx={styles.textButton}>
              <Box component="span" sx={styles.toggle}>
                {invoiceViewState
                  ? t('Invoicing.PaymentSuccessPage.HidePaidInvoiceDetails')
                  : t('Invoicing.PaymentSuccessPage.ViewPaidInvoiceDetails')}
              </Box>
              <FontAwesomeIcon
                icon={invoiceViewState ? faAngleDoubleUp : faAngleDoubleDown}
              />
            </Button>
          )}
          {invoiceViewState && !isMobile && (
            <TableContainer component={Paper} sx={styles.tableContainer}>
              <Table id="invoicesPaymentTable" sx={styles.invoicesPaymentTable}>
                <Box component="caption" sx={visuallyHidden}>
                  {t('Invoicing.Invoices')}
                </Box>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('Invoicing.CustomerName')}</TableCell>
                    <TableCell>{t('Invoicing.CustomerNumber')}</TableCell>
                    <TableCell>{t('Invoicing.InvoiceNumber')}</TableCell>
                    <TableCell>{t('Invoicing.InvoiceDate')}</TableCell>
                    <TableCell>{t('Invoicing.OriginalAmount')}</TableCell>
                    <TableCell>{t('Invoicing.OpenAmount')}</TableCell>
                    <TableCell>{t('Invoicing.PaidAmount')}</TableCell>
                    <TableCell>{t('Invoicing.Status')}</TableCell>
                    <TableCell>{t('Invoicing.Company')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoicePayments.map((invoice, index) => (
                    <TableRow
                      key={`invoice-${invoice.id}`}
                      data-testid={`PaymentSuccess_Payment_Row_${index}`}>
                      <TableCell
                        data-testid={`PaymentSuccess_CustomerName_${index}`}>
                        <OverFlowToolTip>
                          {invoice.customerName}
                        </OverFlowToolTip>
                      </TableCell>
                      <TableCell
                        data-testid={`PaymnetSuccess_CustomerNumber_${index}`}>
                        <OverFlowToolTip>
                          {invoice.customerNumber}
                        </OverFlowToolTip>
                      </TableCell>
                      <TableCell
                        data-testid={`PaymentSuccess_InvoiceNumber_${index}`}>
                        <OverFlowToolTip>
                          {invoice.invoiceNumber}
                        </OverFlowToolTip>
                      </TableCell>
                      <TableCell
                        sx={{ 'text-transform': 'uppercase' }}
                        data-testid={`PaymentSuccess_Date_${index}`}>
                        <OverFlowToolTip>
                          {format(new Date(`${invoice.date}`), 'MMM d, yyyy')}
                        </OverFlowToolTip>
                      </TableCell>
                      <TableCell
                        data-testid={`PaymentSuccess_OriginalAmount_${index}`}>
                        <OverFlowToolTip>
                          {formatCurrency(invoice.originalAmount)}
                        </OverFlowToolTip>
                      </TableCell>
                      <TableCell
                        data-testid={`PaymnetSuccess_OpenAmount_${index}`}>
                        <OverFlowToolTip>
                          {formatCurrency(invoice.openAmount)}
                        </OverFlowToolTip>
                      </TableCell>
                      <TableCell
                        data-testid={`PaymentSuccess_PaidAmount_${index}`}>
                        <OverFlowToolTip>
                          {formatCurrency(invoice.paidAmount)}
                        </OverFlowToolTip>
                      </TableCell>
                      <TableCell data-testid={`PaymentSuccess_Status_${index}`}>
                        <OverFlowToolTip>{invoice.status}</OverFlowToolTip>
                      </TableCell>
                      <TableCell
                        data-testid={`PaymentSuccess_Company_${index}`}>
                        <OverFlowToolTip>{invoice.company}</OverFlowToolTip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <hr />
            </TableContainer>
          )}
        </Box>
      </Box>
    </>
  )
}

export default PayNowPaymentSuccess

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Snackbar, Alert, IconButton } from '@mui/material'
import Slide from '@mui/material/Slide'
import { AnalyticEvent } from 'src/analytics/adobe/types'
import { CloseIcon, CircleErrorIcon } from '../../../icons'
import tokens from '../../../../styles/tokens.json'

const analyticEventType: AnalyticEvent = 'FailureBannerPage_View'

export type OwnProps = {
  message?: string
  header?: string
  open: boolean
  close: () => void
  testId: string
  disableAutoHide?: boolean
  customMessageSlot?: React.ReactNode
  displayInModal?: boolean
}

const CustomErrorAlert = ({
  header = undefined,
  message = undefined,
  open,
  close,
  testId,
  disableAutoHide = false,
  customMessageSlot = null,
  displayInModal = false,
}: OwnProps) => {
  const { t } = useTranslation()
  const toastMsgTimelimit = disableAutoHide ? null : 4000
  const toastMsgSlideEnter = 1000
  const toastMsgSlideExit = 1000

  const [hasLoggedEvent, setHasLoggedEvent] = useState(false)
  useEffect(() => {
    if (open && !hasLoggedEvent) {
      // Log Pendo event when snackbar is shown
      ;(window as any).pendo?.track(analyticEventType, {
        message,
        header,
        testId,
        dataAnalyticsId: testId,
      })
      setHasLoggedEvent(true)
    }
  }, [open, hasLoggedEvent, message, header, testId])

  return (
    <Box
      sx={
        displayInModal
          ? {
              position: 'inherit',
              height: '6.125rem',
            }
          : {}
      }>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transitionDuration={{
          enter: toastMsgSlideEnter,
          exit: toastMsgSlideExit,
        }}
        TransitionComponent={Slide}
        autoHideDuration={toastMsgTimelimit}
        open={open}
        onClose={() => close()}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          right: displayInModal ? '0rem !important' : '1.5rem !important',
          minHeight: '5.125rem',
          position: displayInModal ? 'absolute' : '',
          top: displayInModal ? '0rem !important' : '',
          left: displayInModal ? '0rem !important' : '',
        }}
        data-testid={`${testId}_Snackbar`}
        data-analytic-event={analyticEventType}
        data-analytic-id={testId}>
        <Alert
          role="presentation"
          aria-atomic="true"
          aria-live="assertive"
          sx={() => ({
            backgroundColor: tokens.colors.warning.main,
            fontWeight: 500,
            color: '#515356',
            display: 'flex',
            alignItems: 'left',
            justifyContent: 'left',
            position: 'relative',
            width: '100%',
            minHeight: 'Hug(5.125rem)',
            paddingTop: '0.5rem',
            '.MuiAlert-icon': {
              margin: 0,
              paddingLeft: '0.625rem',
            },
            fontFamily: 'Prelo-Book',
            '.MuiAlert-message': {
              paddingRight: '3rem',
              '.subTitle': {
                fontSize: '1.5rem',
              },
              width: '100%',
            },
            '.MuiAlert-action': {
              position: 'absolute',
              top: '0.313rem',
              right: '1.875rem',
            },
          })}
          severity="error"
          onClose={close}
          icon={
            <CircleErrorIcon
              size="1.375rem"
              color={tokens.colors.warning.contrastText}
            />
          }
          action={
            <IconButton
              autoFocus
              aria-label={t('Alert.CloseAlert')}
              role="button"
              onClick={close}
              disableFocusRipple
              data-testid={`${testId}_CloseButton`}>
              <CloseIcon size={18} color={tokens.colors.warning.contrastText} />
            </IconButton>
          }>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: '1rem',
            }}>
            <Box
              role="alert"
              sx={{
                fontSize: '1rem',
                textAlign: {
                  textAlign: 'left',
                  display: 'flex',
                  flexDirection: 'column',
                },
              }}
              style={{ color: tokens.colors.warning.contrastText }}>
              {customMessageSlot ? (
                <Box data-testid={`${testId}_CustomErrorContainer`}>
                  {customMessageSlot}
                </Box>
              ) : (
                <>
                  <Box
                    component="span"
                    className="title"
                    sx={{
                      fontSize: '1.25rem',
                      lineHeight: '1.875rem',
                    }}
                    data-testid={`${testId}_Header`}
                    data-analytic-event={analyticEventType}
                    data-analytic-id={`${testId}_Header`}>
                    {header ? t(header) : ''}
                  </Box>
                  <Box
                    sx={{
                      lineHeight: '1.2512rem',
                      fontSize: '0.875rem',
                    }}
                    data-testid={`${testId}_Message`}>
                    {message ? t(message) : ''}
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default CustomErrorAlert

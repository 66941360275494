import React from 'react'
import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ResponsiveModal from '../../../rsmCoreComponents/components/ResponsiveModal'
import { Styles } from '../../../types'
import CheckCircleBig from '../../icons/CheckCircleIcon/CheckCircleBig'
import { formatCurrencyWithCurrencyCode } from '../../../rsmCoreComponents/utils/formatters'

interface ConfirmAmountDialogProps {
  open: boolean
  onClose: () => void
  payableAmount: number
  checkPartialAmountDifference: boolean
}

const styles: Styles = {
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
    maxHeight: '100%',
    height: '100%',
    border: '0',
    padding: '0.5rem',
  },
  title: {
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '2.25rem',
    lineHeight: '2.5rem',
    marginTop: '2rem',
    marginBottom: '1rem',
    border: '0',
    textAlign: 'center',
    padding: '0.5rem',
  },
  acceptWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1.5rem',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    border: '0',
  },
}

const ConfirmAmountDialog = ({
  open,
  onClose,
  payableAmount,
  checkPartialAmountDifference,
}: ConfirmAmountDialogProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const desktopMaxWidth = checkPartialAmountDifference ? '46rem' : '41rem'
  const desktopMaxHeight = checkPartialAmountDifference ? '35rem' : '30rem'

  const continueToPaymentType = () => {
    navigate('/invoicing/invoices/payment-type')
    onClose()
  }

  return (
    <Box className="Rsm-ProfileModal">
      <ResponsiveModal
        open={open}
        onClose={onClose}
        aria-label={t('Invoicing.ConfirmAmountDialog.Title')}
        desktopMaxHeight={desktopMaxHeight}
        desktopMaxWidth={desktopMaxWidth}
        removeHeaderSpacing
        data-testid="Lbl_Invoicing_ConfirmAmountDialog_Modal">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell sx={styles.dialogContent}>
                <CheckCircleBig />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={styles.title}
                data-testid="Tbl_Invoicing_ConfirmAmountDialog_TitleRow">
                {t('Invoicing.ConfirmAmountDialog.Title')}
              </TableCell>
            </TableRow>
            {checkPartialAmountDifference && (
              <TableRow>
                <TableCell
                  sx={styles.dialogContent}
                  data-testid="Tbl_Invoicing_ConfirmAmountDialog_PartialPaymentNote">
                  <Typography variant="body2">
                    <span style={{ fontWeight: 'bold' }}>
                      {t('Invoicing.PartialPaymentToolTipNote')}
                    </span>
                    {t('Invoicing.ConfirmAmountDialog.PartialAmountText')}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell
                sx={styles.dialogContent}
                data-testid="Tbl_Invoicing_ConfirmAmountDialog_ConfirmAmountReview">
                <Typography variant="body1">
                  {t('Invoicing.ConfirmAmountDialog.ConfirmAmountReview')}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={styles.title}
                data-testid="Tbl_Invoicing_ConfirmAmountDialog_AmountRow">
                {formatCurrencyWithCurrencyCode(payableAmount)}{' '}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={styles.acceptWrapper}>
                <Stack sx={styles.acceptWrapper}>
                  <Button
                    variant="outlined"
                    data-testid="Btn_ConfirmAmountDialog_Cancel"
                    onClick={onClose}>
                    {t('Invoicing.ConfirmAmountDialog.Cancel')}
                  </Button>
                  <Button
                    variant="contained"
                    data-testid="Btn_ConfirmAmountDialog_ProceedtoPayment"
                    onClick={continueToPaymentType}>
                    {t('Invoicing.ConfirmAmountDialog.Proceed')}
                  </Button>
                </Stack>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </ResponsiveModal>
    </Box>
  )
}

export default ConfirmAmountDialog

import React, { useEffect, useState } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ResponsiveModal from '../../rsmCoreComponents/components/ResponsiveModal'
import { Styles } from '../../types'
import HtmlViewer from '../../rsmCoreComponents/components/HtmlViewer/HtmlViewer'
import ConsentToElectronicCommunicationsUSAndCanada from '../../invoiceHtmlDocs/ConsentToElectronicCommunicationsUSAndCanada'
import tokens from '../../styles/tokens.json'

interface InvoiceAgreementDialogProps {
  open: boolean
  onAccept: () => void
  onClose: () => void
  headerTestId?: string // For the modal header
  subtitleTestId?: string // For the modal subtitle
  acceptButtonTestId?: string // For the "Accept" button
  declineButtonTestId?: string // For the "Decline" button
}

const styles: Styles = {
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '5%',
    backgroundColor: tokens.colors.white,
    justifyContent: 'center',
    textAlign: 'center',
    maxHeight: '100%',
    height: '100%',
  },
  title: {
    fontFamily: 'Prelo-Light, sans-serif',
    fontSize: '1.5rem',
    lineHeight: '1.5rem',
  },
  subtitle: {
    fontSize: '0.875rem',
  },
  termsConditionsButton: (theme) => ({
    width: '15rem',
    justifyContent: 'space-between',
    alignSelf: 'center',
    '& svg': {
      stroke: theme.palette.secondary.main,
      transition: 'stroke 250ms cubic-bezier(0.4, 0, 0.2, 1)',
    },
    '&:hover svg,': {
      stroke: theme.palette.common.white,
    },
    '&:active svg': {
      stroke: theme.palette.text.primary,
    },
  }),
  acceptDeclineWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexFlow: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1.5rem',
    paddingTop: '2rem',
  },
}

const InvoiceAgreementDialog = ({
  open,
  onAccept,
  onClose,
  headerTestId = 'Lbl_TermsConditions_Header',
  subtitleTestId = 'Lbl_TermsConditions_Subtitle',
  acceptButtonTestId = 'Btn_TermsConditions_Accept',
  declineButtonTestId = 'Btn_TermsConditions_Decline',
}: InvoiceAgreementDialogProps) => {
  const { t } = useTranslation()
  const [agreement, setAgreement] = useState('')
  const [isRead, setIsRead] = useState(false)

  useEffect(() => {
    if (open) {
      setAgreement(ConsentToElectronicCommunicationsUSAndCanada)
    }
  }, [open])

  return (
    <Box className="Rsm-ProfileModal">
      <ResponsiveModal
        open={open}
        onClose={onClose}
        aria-label={t('Invoicing.AgreementModal.TermsConditionInv')}
        desktopMaxHeight="70.2rem"
        desktopMaxWidth="51.6rem"
        removeHeaderSpacing>
        <Box sx={styles.dialogContent}>
          <Typography variant="h1" sx={styles.title} data-testid={headerTestId}>
            {t('Invoicing.AgreementModal.TermsConditionInv')}
          </Typography>
          <Typography
            variant="subtitle1"
            component="span"
            sx={styles.subtitle}
            data-testid={subtitleTestId}>
            {t('Invoicing.AgreementModal.AcceptTermsCondition')}
          </Typography>
          <HtmlViewer
            title={t('Invoicing.AgreementModal.TermsConditionInv')}
            html={agreement}
            onScrolledDown={() => setIsRead(true)}
          />
          <Stack sx={styles.acceptDeclineWrapper}>
            <Button
              variant="contained"
              onClick={onAccept}
              disabled={!isRead}
              data-testid={acceptButtonTestId}>
              {t('Invoicing.Accept')}
            </Button>
            <Button
              variant="outlined"
              onClick={onClose}
              disabled={!isRead}
              data-testid={declineButtonTestId}>
              {t('Invoicing.Decline')}
            </Button>
          </Stack>
        </Box>
      </ResponsiveModal>
    </Box>
  )
}

export default InvoiceAgreementDialog

import React, { useState, useCallback, useEffect } from 'react'
import { styled } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import { InvoicePayerViewModel } from '@rsmus/ecp-financeservice'
import TermsAndConditionsDialog from './TermsAndConditionsDialog'
import {
  getPayableAmount,
  getInvoicePayments,
  setPaymentTransaction,
  setInvoicePayments,
  InvoicesWithPayments,
  ConfirmPaymentResponse,
} from '../../../store/invoices/invoiceSelectedInvoicesSlice'
import {
  getSelectedBankAccount,
  getSelectedCreditCard,
  getSelectedPayer,
  getSelectedPaymentMethod,
  getDoNotSave,
  setConfirmPaymentError,
} from '../../../store/invoices/paymentInfoSlice'
import api from '../../../api'
import Spinner from '../../forms/Spinner/Spinner'
import PaymentMethod from './PaymentMethod'
import Payer from './PaymentComponents/Payer'
import AccountInfoSubText from './PaymentComponents/AccountInfoSubText'

const RowArea = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexBasis: 'auto',
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
  [theme.breakpoints.only('mobile')]: {
    flexDirection: 'column',
  },
}))

interface PaymentTypePayNowProps {
  formSubmitCount: number
  payerChange: (payer: InvoicePayerViewModel | undefined) => void
  sessionCreating: () => void
  sessionChange: (sessionId: string, isError: boolean) => void
}

const PaymentTypePayNow = ({
  formSubmitCount,
  payerChange,
  sessionCreating,
  sessionChange,
}: PaymentTypePayNowProps) => {
  const { reset, getValues } = useFormContext()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const payableAmount = useSelector(getPayableAmount)
  const invoicePayments = useSelector(getInvoicePayments)
  const doNotSave = useSelector(getDoNotSave)
  const selectedBankAccount = useSelector(getSelectedBankAccount)
  const selectedCreditCard = useSelector(getSelectedCreditCard)
  const selectedPayer = useSelector(getSelectedPayer)
  const selectedPaymentMethod = useSelector(getSelectedPaymentMethod)

  const [openTermsDialog, setOpenTermsDialog] = useState<boolean>(false)
  const [currentPayer, setCurrentPayer] = useState<
    InvoicePayerViewModel | undefined
  >(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleConfirmPayment = useCallback(async () => {
    setIsLoading(true)

    try {
      const response = await api.finance.payment_ConfirmPayment({
        clientPaymentMethodId:
          selectedPaymentMethod === 'BankAccount'
            ? selectedBankAccount ?? 0
            : selectedCreditCard ?? 0,
        payerName: selectedPayer?.name ?? '',
        amount: payableAmount,
        doNotSave,
        invoices: invoicePayments.map((invoice) => ({
          invoiceId: invoice.id,
          invoiceNumber: invoice.invoiceNumber,
          amount: invoice.paidAmount,
        })),
      })

      if (response?.data?.isSuccess || false) {
        const paymentTransaction: ConfirmPaymentResponse = {
          accountNumber: response.data?.accountNumber,
          isSuccess: response.data?.isSuccess,
          transactionDate: response.data?.transactionDate,
          transactionId: response.data?.transactionId,
          payer: selectedPayer?.name ?? '',
          paymentMethod: selectedPaymentMethod,
        }

        const invoicesWithPayments: InvoicesWithPayments = invoicePayments.map(
          (invoice) => ({
            company: invoice.company,
            id: invoice.id,
            invoiceNumber: invoice.invoiceNumber,
            paidAmount: invoice.paidAmount,
            currency: invoice.currency,
            customerName: invoice.customerName,
            customerNumber: invoice.customerNumber,
            date: invoice.date,
            invoicePaidDate: invoice.invoicePaidDate,
            originalAmount: invoice.originalAmount,
            openAmount: invoice.openAmount - invoice.paidAmount,
            status:
              invoice.openAmount - invoice.paidAmount > 0
                ? t('Invoicing.Open')
                : t('Invoicing.Closed'),
            scheduledPaymentType: invoice.scheduledPaymentType,
            scheduledDate: invoice.scheduledDate,
          }),
        )
        dispatch(setInvoicePayments(invoicesWithPayments))
        dispatch(setPaymentTransaction(paymentTransaction))

        reset()
        setIsLoading(false)
        navigate('/invoicing/invoices/payment-success')
      } else {
        dispatch(setConfirmPaymentError(true))
        setIsLoading(false)
      }
    } catch {
      dispatch(setConfirmPaymentError(true))
      setIsLoading(false)
    }
  }, [
    doNotSave,
    payableAmount,
    selectedPaymentMethod,
    selectedBankAccount,
    selectedCreditCard,
    invoicePayments,
    selectedPayer,
    getValues,
    dispatch,
    reset,
    navigate,
  ])

  useEffect(() => {
    if (selectedPayer && selectedPayer !== currentPayer) {
      setCurrentPayer(selectedPayer)
      payerChange(selectedPayer)
    }
  }, [selectedPayer, currentPayer, payerChange])

  // When the form is submitted, open the terms and conditions dialog.
  useEffect(() => {
    if (formSubmitCount > 0) {
      setOpenTermsDialog(true)
    }
  }, [formSubmitCount])

  return (
    <>
      <Spinner open={isLoading} />
      <RowArea>
        <Payer payerChange={payerChange} />
      </RowArea>
      <AccountInfoSubText />
      <RowArea sx={{ marginTop: '0.5rem' }}>
        <PaymentMethod
          paymentMethodType={selectedPaymentMethod}
          isScheduledPayment={false}
          sessionCreating={sessionCreating}
          sessionChange={sessionChange}
        />
      </RowArea>
      <TermsAndConditionsDialog
        isAutomaticPayment={false}
        open={openTermsDialog}
        onAccept={() => {
          setOpenTermsDialog(false)
          handleConfirmPayment()
        }}
        onClose={() => setOpenTermsDialog(false)}
        hasUserReadTerms={false}
        testId="Btn_InvoiceTermsandConditionPage_Paynow_AcceptAndPay"
      />
    </>
  )
}

export default PaymentTypePayNow

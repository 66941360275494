import React, { useEffect, useMemo, useState } from 'react'
import {
  Box,
  FormHelperText,
  Autocomplete,
  TextField,
  styled,
} from '@mui/material'
import { InvoicePayerViewModel } from '@rsmus/ecp-financeservice'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { getSelectedPayer } from 'src/store/invoices/paymentInfoSlice'
import { ArrowDownIcon } from '../../../icons'
import { Styles } from '../../../../types'
import { getInvoicePayers } from '../../../../store/invoices/invoiceSelectedInvoicesSlice'
import { tokens } from '../../../../styles/materialTheme'

interface PayerProps {
  payerChange: (payer: InvoicePayerViewModel | undefined) => void
  errorKey?: string
}

const styles: Styles = {
  SelectPaymentpayerContainer: (theme) => ({
    width: 'calc(69% - 4rem)',
    marginTop: '0.5rem',
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
      paddingLeft: 0,
      paddingBottom: '0.05rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: 0,
      width: 'calc(50% - 4rem)',
    },
  }),
  PayerContainer: (theme) => ({
    [theme.breakpoints.only('tablet')]: {
      width: '100%',
    },
  }),
  requiredError: {
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '0.875rem',
    color: tokens.colors.rsmRed.secondary,
  },
}

const StyledLabel = styled('label')(({ theme }) => ({
  fontFamily: 'Prelo-Black, sans-serif',
  fontSize: '1rem',
  paddingBottom: '0.5rem',
  color: theme.palette.text.primary,
  display: 'block',
}))

const Payer = ({
  payerChange,
  errorKey = 'Invoicing.PayerRequired',
}: PayerProps) => {
  const { control, setValue } = useFormContext()
  const invoicePayers = useSelector(getInvoicePayers)
  const selectedPayer = useSelector(getSelectedPayer)
  const [currentPayer, setCurrentPayer] = useState<
    InvoicePayerViewModel | undefined
  >(undefined)
  const { t } = useTranslation()

  const singlePayer = useMemo(() => {
    const payer = invoicePayers.length === 1 ? invoicePayers[0] : null
    return payer
  }, [invoicePayers])

  useEffect(() => {
    if (singlePayer && singlePayer !== currentPayer) {
      payerChange(singlePayer)
      setCurrentPayer(singlePayer)
    }

    // this sets the value on the autocomplete component when single payer
    // to prevent error from occurring as the single payer is set by default
    // even if you clear the value out
    if (singlePayer && singlePayer === currentPayer) {
      // only call payerChange if selected payer object is null
      // selectedPayer will be null after value is cleared from dropdown
      // and needs to be reset during the auto populate process
      if (!selectedPayer) {
        payerChange(singlePayer)
      }
      setValue('payer', singlePayer)
    }
  }, [singlePayer, currentPayer, payerChange])

  return (
    <Box sx={styles.PayerContainer}>
      <StyledLabel htmlFor="payer" data-testid="Lbl_Invoicing_Payer">
        {t('Invoicing.Payer')}
        <span className="sr-only">{t('srOnlyRequired')}</span>
      </StyledLabel>
      <Box id="payerMessage" data-testid="Txt_Invoicing_PayerMessage">
        <Box component="i">{t('Invoicing.PayerMessage')}</Box>
      </Box>
      <Controller
        name="payer"
        control={control}
        defaultValue=""
        rules={{ required: `${t(errorKey)}` }}
        render={({
          field: { onChange, value, ref },
          fieldState: { error },
        }) => (
          <>
            <Autocomplete
              popupIcon={<ArrowDownIcon />}
              id="payer"
              data-testid="Autocomplete_Invoicing_Payer"
              fullWidth
              value={singlePayer || value}
              onChange={(
                event: React.SyntheticEvent,
                newValue: InvoicePayerViewModel | null,
              ) => {
                onChange(newValue)
                payerChange(newValue || undefined)
              }}
              options={invoicePayers}
              getOptionLabel={(payer) =>
                payer ? `${payer.name} ( ${payer.payerId} )` : ''
              }
              isOptionEqualToValue={(option, val) =>
                option?.payerId === val?.payerId
              }
              sx={styles.SelectPaymentpayerContainer}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  inputRef={ref}
                  error={!!error}
                  inputProps={{
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    ...params.inputProps,
                    'aria-describedby': error
                      ? 'payerErrorText'
                      : 'payerMessage',
                  }}
                  placeholder={t('searchByPayor')}
                />
              )}
            />
            {error && (
              <FormHelperText
                id="payerErrorText"
                sx={styles.requiredError}
                data-testid="Txt_Invoicing_PayerError">
                {error.message}
              </FormHelperText>
            )}
          </>
        )}
      />
    </Box>
  )
}

export default Payer

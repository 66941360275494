import React, { useState, useCallback, useEffect } from 'react'
import { styled } from '@mui/material'
import {
  InvoicePayerViewModel,
  PaymentInstallmentFrequencyEnum,
} from '@rsmus/ecp-financeservice'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import TermsAndConditionsDialog from './TermsAndConditionsDialog'
import {
  getPayableAmount,
  getInvoicePayments,
  setInvoicePayments,
  setScheduledInstallments,
  ScheduledInstallments,
  InvoicesWithPayments,
} from '../../../store/invoices/invoiceSelectedInvoicesSlice'
import {
  getSelectedBankAccount,
  getSelectedCreditCard,
  getSelectedPayer,
  getSelectedPaymentMethod,
  setConfirmPaymentError,
} from '../../../store/invoices/paymentInfoSlice'
import api from '../../../api'
import Spinner from '../../forms/Spinner/Spinner'
import PaymentMethod from './PaymentMethod'
import PaymentDate from './PaymentDate'
import InstallmentFrequency from './PaymentComponents/InstallmentFrequency'
import InstallmentPlan from './PaymentComponents/InstallmentPlan'
import InstallmentPlanOther from './PaymentComponents/InstallmentPlanOther'
import Payer from './PaymentComponents/Payer'
import AccountInfoSubText from './PaymentComponents/AccountInfoSubText'

const RowArea = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexBasis: 'auto',
  marginTop: '1rem',
  marginBottom: '1.5rem',
  [theme.breakpoints.only('mobile')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.only('desktop')]: {
    marginTop: '1.5rem',
  },
}))

interface PaymentTypeInstallmentsProps {
  formSubmitCount: number
  payerChange: (payer: InvoicePayerViewModel | undefined) => void
  sessionCreating: () => void
  sessionChange: (sessionId: string, isError: boolean) => void
}

const PaymentTypeInstallments = ({
  formSubmitCount,
  payerChange,
  sessionCreating,
  sessionChange,
}: PaymentTypeInstallmentsProps) => {
  const { reset, getValues, watch } = useFormContext()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const payableAmount = useSelector(getPayableAmount)
  const invoicePayments = useSelector(getInvoicePayments)
  const selectedBankAccount = useSelector(getSelectedBankAccount)
  const selectedCreditCard = useSelector(getSelectedCreditCard)
  const selectedPayer = useSelector(getSelectedPayer)
  const selectedPaymentMethod = useSelector(getSelectedPaymentMethod)

  const [openTermsDialog, setOpenTermsDialog] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleConfirmPayment = useCallback(async () => {
    setIsLoading(true)

    try {
      let numberOfPayments = getValues('numberOfInstallments')
      if (numberOfPayments === 'other') {
        numberOfPayments = getValues('numberOfInstallmentsOther')
      }

      const scheduledDate = new Date(getValues('paymentDate'))
      const response = await api.finance.payment_SchedulePayments({
        amount: payableAmount,
        scheduledDate,
        clientPaymentMethodId:
          selectedPaymentMethod === 'BankAccount'
            ? selectedBankAccount ?? 0
            : selectedCreditCard ?? 0,
        payerName: selectedPayer?.name ?? '',
        invoices: invoicePayments.map((invoice) => ({
          invoiceId: invoice.id,
          invoiceNumber: invoice.invoiceNumber,
          amount: invoice.paidAmount,
        })),
        installmentFrequency:
          getValues('frequency') || PaymentInstallmentFrequencyEnum.OneTime,
        numberOfPayments,
      })

      const scheduledInstallments: ScheduledInstallments = {
        accountNumber: response.data?.accountNumber,
        installmentPlanId: response.data?.installmentPlanId ?? 0,
        installments: response.data?.installments,
        payer: selectedPayer?.name ?? '',
        paymentMethod: selectedPaymentMethod,
        installmentPlan: `${
          getValues('frequency') || PaymentInstallmentFrequencyEnum.OneTime
        } / ${numberOfPayments} ${t('Invoicing.Installments').toLowerCase()}`,
      }

      const invoicesWithPayments: InvoicesWithPayments = invoicePayments.map(
        (invoice) => ({
          company: invoice.company,
          id: invoice.id,
          invoiceNumber: invoice.invoiceNumber,
          paidAmount: invoice.paidAmount,
          openAmount: invoice.openAmount,
          currency: invoice.currency,
          customerName: invoice.customerName,
          customerNumber: invoice.customerNumber,
          date: invoice.date,
          invoicePaidDate: invoice.invoicePaidDate,
          originalAmount: invoice.originalAmount,
          status: t('Invoicing.Scheduled'),
          scheduledPaymentType: invoice.scheduledPaymentType,
          scheduledDate: invoice.scheduledDate,
        }),
      )
      dispatch(setInvoicePayments(invoicesWithPayments))
      dispatch(setScheduledInstallments(scheduledInstallments))

      reset()
      setIsLoading(false)
      navigate('/invoicing/invoices/payment-success')
    } catch {
      dispatch(setConfirmPaymentError(true))
      setIsLoading(false)
    }
  }, [
    payableAmount,
    selectedPaymentMethod,
    selectedBankAccount,
    selectedCreditCard,
    invoicePayments,
    selectedPayer,
    getValues,
    dispatch,
    reset,
    navigate,
  ])

  const getPaymentAmounts = useCallback(
    (numberOfPayments: number) => {
      const frequency = getValues(
        'frequency',
      ) as PaymentInstallmentFrequencyEnum
      let pennies = (payableAmount * 100) % numberOfPayments
      const monthlyPayment = Math.floor(
        (payableAmount / numberOfPayments) * 100,
      )

      return Array.from({ length: numberOfPayments }, (_, i) => {
        pennies -= 1
        const extraPenny = pennies > 0 ? 1 : 0
        const amount = (monthlyPayment + extraPenny) / 100
        const installmentNumber = i + 1
        const scheduledDate = new Date(getValues('paymentDate'))

        if (frequency === PaymentInstallmentFrequencyEnum.Monthly) {
          scheduledDate.setMonth(
            scheduledDate.getMonth() + installmentNumber - 1,
          )
        } else {
          let daysToAdd = 0
          if (frequency === PaymentInstallmentFrequencyEnum.Weekly) {
            daysToAdd = 7
          } else if (frequency === PaymentInstallmentFrequencyEnum.BiWeekly) {
            daysToAdd = 14
          }

          scheduledDate.setDate(
            scheduledDate.getDate() + daysToAdd * installmentNumber,
          )
        }

        return {
          Amount: amount,
          ScheduledDate: scheduledDate,
        }
      })
    },
    [payableAmount, getValues],
  )

  // When the form is submitted, open the terms and conditions dialog.
  useEffect(() => {
    if (formSubmitCount > 0) {
      setOpenTermsDialog(true)
    }
  }, [formSubmitCount])

  return (
    <>
      <Spinner open={isLoading} />
      <RowArea>
        <InstallmentFrequency />
      </RowArea>
      <RowArea>
        <PaymentDate
          labelKey="Invoicing.PaymentsStartDate"
          errorKey="Invoicing.PaymentsStartDateRequired"
        />
      </RowArea>
      <RowArea>
        <InstallmentPlan getPaymentAmounts={getPaymentAmounts} />
      </RowArea>
      {watch('numberOfInstallments') === 'other' && (
        <RowArea sx={{ marginTop: 0 }}>
          <InstallmentPlanOther getPaymentAmounts={getPaymentAmounts} />
        </RowArea>
      )}
      <RowArea>
        <Payer payerChange={payerChange} />
      </RowArea>
      <AccountInfoSubText />
      <RowArea sx={{ marginTop: '0.5rem' }}>
        <PaymentMethod
          sessionCreating={sessionCreating}
          sessionChange={sessionChange}
          paymentMethodType={selectedPaymentMethod}
          isScheduledPayment
        />
      </RowArea>
      <TermsAndConditionsDialog
        isAutomaticPayment
        open={openTermsDialog}
        onAccept={() => {
          setOpenTermsDialog(false)
          handleConfirmPayment()
        }}
        onClose={() => setOpenTermsDialog(false)}
        hasUserReadTerms={false}
        testId="Btn_InvoiceTermsandConditionPage_SetUpInstallment_AcceptAndPay"
      />
    </>
  )
}

export default PaymentTypeInstallments

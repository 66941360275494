import { env as env2 } from '@rsmus/react-env-image'

/*
    The following are values found in either the .env files or in the environment variables on the machine.
    Environment variables from the machine are preferred, but .env files can be useful in development.  In such
    cases, use .env.development, since that will not propagate to the production environment.
*/

// This is a temporary work around to allow tests to succeed.  The current env function will throw
//  errors if the window object doesn't have a config property.  The offending NPM package should be
//  updated soon, and this if-block can be removed at that time.
let env = env2
if ((window as any)?.config == null) {
  env = function markUndefinedConfigs() {
    return undefined
  }
}

export const CMSSERVICE_BASE_URL = env('REACT_APP_CMSSERVICE_BASE_URL')
export const COMMUNICATIONSERVICE_BASE_URL = env(
  'REACT_APP_COMMUNICATIONS_SERVICE_BASE_URL',
)
export const DOCUMENTREQUESTSERVICE_BASE_URL = env(
  'REACT_APP_DOCUMENTREQUEST_SERVICE_BASE_URL',
)
export const DOCUMENTREQUEST_ASSIGNEE_MAX_COUNT = env(
  'REACT_APP_DOCUMENTREQUEST_ASSIGNEE_MAX_COUNT',
)

export const USERSERVICE_BASE_URL = env('REACT_APP_USER_SERVICE_BASE_URL')
export const FINANCESERVICE_BASE_URL = env('REACT_APP_FINANCE_SERVICE_BASE_URL')
export const APP_REACT_AUTH_AUTHORITY = env('REACT_APP_AUTH_AUTHORITY')
export const APP_REACT_AUTH_CLIENTID = env('REACT_APP_AUTH_CLIENTID')

export const CEMSERVICE_BASE_URL = env('REACT_APP_CEM_SERVICE_BASE_URL')
export const IDMSERVICE_BASE_URL = env('REACT_APP_IDM_SERVICE_BASE_URL')
export const IDM_EULA_SERVICE_URL = env('REACT_APP_IDM_EULA_SERVICE_URL')

export const INTEREST_REMINDER_FREQUENCY = env(
  'REACT_APP_INTEREST_REMINDER_FREQUENCY',
)
export const BROWSER_TIMEOUT = env('REACT_APP_BROWSER_ACTIVITY_TIMEOUT')
export const PAYMENT_TIMEOUT_DELAY = env('REACT_APP_PAYMENT_TIMEOUT_DELAY')
export const INSIGHT_CONTENT_IMAGE_PREFIX = env(
  'REACT_APP_INSIGHTS_ARTICLE_IMAGE_PREFIX',
)
export const REACT_APP_RSMUS_DOT_COM_LIVENESS_CHECK = env(
  'REACT_APP_RSMUS_DOT_COM_LIVENESS_CHECK',
)
export const FEATURE_MANAGEMENT = env('FeatureManagement')
export const APP_INSIGHTS_INSTRUMENTATION_KEY = env(
  'REACT_APP_APP_INSIGHTS_INSTRUMENTATION_KEY',
)

export const CEM_ENGAGEMENT_MAX = env('REACT_APP_CEM_ENGAGEMENT_MAX')
export const PAYMENT_GATEWAY_IFRAME_URL = env(
  'REACT_APP_PAYMENT_GATEWAY_IFRAME_URL',
)
export const REACT_APP_INSTALLMENT_FREQUENCIES = env(
  'REACT_APP_INSTALLMENT_FREQUENCIES',
)

export const REACT_APP_POP_IMAGE = env('REACT_APP_POP_IMAGE')

export const REACT_APP_TEAMDOCS_DASHBOARD_URL = env(
  'REACT_APP_TEAMDOCS_DASHBOARD_URL',
)
export const REACT_APP_TEAMDOCS_OKTA_APP_ID = env(
  'REACT_APP_TEAMDOCS_OKTA_APP_ID',
)

export const REACT_APP_PAYONACCOUNT_MIN_PAYMENT_AMOUNT = env(
  'REACT_APP_PAYONACCOUNT_MIN_PAYMENT_AMOUNT',
)
export const REACT_APP_PAYONACCOUNT_MAX_PAYMENT_AMOUNT = env(
  'REACT_APP_PAYONACCOUNT_MAX_PAYMENT_AMOUNT',
)

export const REACT_APP_AUTOPAY_MIN_PAYMENT_AMOUNT = env(
  'REACT_APP_AUTOPAY_MIN_PAYMENT_AMOUNT',
)
export const REACT_APP_AUTOPAY_MAX_PAYMENT_AMOUNT = env(
  'REACT_APP_AUTOPAY_MAX_PAYMENT_AMOUNT',
)

export const REACT_APP_DISALLOWED_TOOLS = env('REACT_APP_DISALLOWED_TOOLS')

export const OKTA_DASHBOARD_URL = env('REACT_APP_OKTA_DASHBOARD_URL')
